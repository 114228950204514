// @ts-strict-ignore
import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { capitalize } from 'underscore.string';
import Image from 'next/image';
import { useDispatch, useSelector } from 'src/store/store';
import config from 'config/config';
import i18n from 'src/utils/translate';
import { footfall } from 'src/components/siteBar/sitebarFootfall';
import { Section } from 'src/constants/footfall';
import { fetchNotifications } from 'src/modules/shared/context/actions';
import HubMenu from 'src/components/siteBar/hubMenu';
import NotificationMenu from 'src/components/siteBar/notificationMenu';
import DesktopMoreMenu from 'src/components/siteBar/desktopMoreMenu';
import { Avatar, Counter, ImageContainer, ImageIcon, LinkText, TopSiteBar, StyledLink, StyledSearchBar } from 'src/components/siteBar/sharedStyles';
import PaperPlane from 'src/components/siteBar/icons/paperPlane';
import Pen from 'src/components/siteBar/icons/pen';

export default function DesktopSiteBar() {
  const { pathname } = useRouter();
  const dispatch = useDispatch();
  const unreadMessagesCount = useSelector(({ context }) => context.unreadMessagesCount);
  const { username, avatarImageUrl } = useSelector(({ context }) => context.currentUser);
  const isOwnProfile = useSelector(({ pages }) => pages.profile?.profileUser?.isOwnProfile);
  const menuRef = useRef<HTMLDivElement>(null);
  const [openHubMenu, setOpenHubMenu] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openMoreMenu, setOpenMoreMenu] = useState(false);

  const closeAllMenus = () => {
    setOpenHubMenu(false);
    setOpenNotifications(false);
    setOpenMoreMenu(false);
  };

  const handleClickOutside = (event) => {
    if (!menuRef?.current?.contains(event.target)) closeAllMenus();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []); // eslint-disable-line

  return (
    <TopSiteBar data-testid="desktop-sitebar" ref={menuRef}>
      <Inner>
        <LeftSide>
          <Link
            legacyBehavior
            passHref
            href="/newsfeed"
          >
            <A
              $isActive={pathname.includes('newsfeed')}
              data-testid="sitebar-newsfeed"
              onClick={() => footfall(Section.Home)}
            >
              <ImageContainer>
                <ImageIcon alt="Home" src={`${config.cdn.logos}H-logo-speech-bubble-green.svg`} />
              </ImageContainer>
              <LinkText $isActive={pathname.includes('newsfeed')}>{capitalize(i18n.t('home'))}</LinkText>
            </A>
          </Link>

          <StyledSearchBar onClick={() => footfall(Section.Search)} />
        </LeftSide>

        <Link
          legacyBehavior
          passHref
          href="/newsfeed"
        >
          <HULogoLink data-testid="sitebar-hu-logo" onClick={() => footfall(Section.HuLogo)}>
            <Image
              alt="HealthUnlocked logo"
              height="18"
              src={`${config.cdn.logos}healthunlocked.svg`}
              width="188"
            />
          </HULogoLink>
        </Link>

        <RightSide>
          <Link
            legacyBehavior
            passHref
            as={`/user/${username}`}
            href={`/user/profile?username=${username}`}
          >
            <A
              $isActive={pathname.includes('user') && isOwnProfile}
              data-testid="sitebar-profile"
              onClick={() => footfall(Section.More)}
            >
              <ImageContainer>
                <Avatar alt="Your avatar" src={avatarImageUrl} />
              </ImageContainer>
              <LinkText $isActive={pathname.includes('user') && isOwnProfile}>{capitalize(i18n.t('profile'))}</LinkText>
            </A>
          </Link>

          <HubMenu
            handleClick={() => {
              footfall(Section.MyHub);
              closeAllMenus();
              setOpenHubMenu(!openHubMenu);
            }}
            isOpen={openHubMenu}
            setOpenHubMenu={setOpenHubMenu}
          />

          <Link
            legacyBehavior
            passHref
            as="/messages"
            href="/messages"
          >
            <A
              $isActive={pathname.includes('messages')}
              data-testid="sitebar-chat"
              onClick={() => footfall(Section.Chat)}
            >
              <ImageContainer>
                <PaperPlane />
                {unreadMessagesCount > 0 && <Counter data-testid="chat-message-number">{unreadMessagesCount}</Counter>}
              </ImageContainer>
              <LinkText $isActive={pathname.includes('messages')}>{capitalize(i18n.t('chat'))}</LinkText>
            </A>
          </Link>

          <Link
            legacyBehavior
            passHref
            as="/write"
            href="/community/write"
          >
            <A
              $isActive={pathname.includes('write')}
              data-testid="sitebar-write"
              onClick={() => footfall(Section.Post)}
            >
              <ImageContainer>
                <Pen />
              </ImageContainer>
              <LinkText $isActive={pathname.includes('write')}>{capitalize(i18n.t('post'))}</LinkText>
            </A>
          </Link>

          <NotificationMenu
            handleClick={() => {
              footfall(Section.Alerts);
              closeAllMenus();
              setOpenNotifications(!openNotifications);
              dispatch(fetchNotifications());
            }}
            isOpen={openNotifications}
            setOpenNotifications={setOpenNotifications}
          />

          <DesktopMoreMenu
            handleClick={() => {
              footfall(Section.More);
              closeAllMenus();
              setOpenMoreMenu(!openMoreMenu);
            }}
            isOpen={openMoreMenu}
            setOpenMoreMenu={setOpenMoreMenu}
          />
        </RightSide>
      </Inner>
    </TopSiteBar>
  );
}

const Inner = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;
const LeftSide = styled.div`
  display: flex;
  ${StyledSearchBar} {
    width: 300px;
    margin-left: 5px;
  }
`;
const RightSide = styled.div`
  display: flex;
`;
const A = styled(StyledLink)`
  ${RightSide} & {
    width: 60px;
  }
`;
const HULogoLink = styled.a`
  display: flex;
  align-items: center;
  @media (${({ theme }) => theme.underScreenMedium}) {
    display: none;
  }
`;
