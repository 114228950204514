// @ts-strict-ignore
import { UseActions } from 'src/components/buttons/followButton/types';
import { followCommunity, showUnfollowModal } from 'src/modules/community/shared/actions';
import FollowSuccessMessage from 'src/modules/community/shared/components/alert/followSuccessMessage';
import { followPost, unfollowPost } from 'src/modules/community/singlePost/actions/actions';
import { followUser, unfollowUser } from 'src/modules/user/profile/actions';
import { useDispatch } from 'src/store/store';
import i18n from 'src/utils/translate';

export function useActions({
  followType, postId, userId, communityName, communitySlug, username,
}: UseActions): { followAction: () => void; unfollowAction: () => void; } {
  const dispatch = useDispatch();

  switch (followType) {
    case 'post':
      return {
        followAction: () => dispatch(followPost(postId)),
        unfollowAction: () => dispatch(unfollowPost(postId)),
      };
    case 'user':
      return {
        followAction: () => dispatch(followUser(userId, `${i18n.t('You are now following')} ${username}`)),
        unfollowAction: () => dispatch(unfollowUser(userId, `${i18n.t('You unfollowed')} ${username}`)),
      };
    case 'community':
    default:
      return {
        followAction: () => dispatch(followCommunity({ communitySlug, successMessage: <FollowSuccessMessage communityName={communityName} /> })),
        unfollowAction: () => dispatch(showUnfollowModal({ communitySlug, communityName })),
      };
  }
}
