// @ts-strict-ignore
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { useSelector } from 'src/store/store';
import config from 'config/config';
import i18n from 'src/utils/translate';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import { getCommunityLogoUrl } from 'src/utils/linkUtils';
import SitebarDropdown, { Divider, SitebarDropdownLink } from 'src/components/siteBar/sitebarDropdown';
import Badge from 'src/components/badge';
import InformationCard from 'src/components/informationCard';
import { discoverLinks } from 'src/modules/home/visitor/components/discoverSection';
import Avatar from 'src/components/avatar';
import { text, textLight } from 'styles-js/mixins/typography';
import Hub from 'src/components/siteBar/icons/hub';
import { DropdownButton, ImageContainer, LinkText } from 'src/components/siteBar/sharedStyles';
import { get } from 'src/utils/api';

const PROGRAM_URL = config.cdn.programsIndex;
const COMMUNITIES_TO_SHOW = 5;
const PROGRAMS_TO_SHOW = 3;

type Props = {
  isOpen: boolean;
  handleClick: (e: SyntheticEvent) => void;
  setOpenHubMenu: (open: boolean) => void;
}
export default function HubMenu({ isOpen, handleClick, setOpenHubMenu }: Props) {
  const { pathname } = useRouter();
  const followedCommunities = useSelector(({ context }) => context.currentUser?.followedCommunities);
  const [showAllCommunities, setShowAllCommunities] = useState(false);
  const [showAllPrograms, setShowAllPrograms] = useState(false);
  const filteredCommunities = useFollowedCommunities(isOpen, showAllCommunities);
  const { programs, loading } = usePrograms(isOpen);
  const filteredPrograms = showAllPrograms ? programs : programs.slice(0, PROGRAMS_TO_SHOW);

  return (
    <div>
      <DropdownButton
        $isActive={isOpen || pathname.toLowerCase().includes('hub')}
        data-testid="sitebar-my-hub"
        onClick={handleClick}
      >
        <ImageContainer>
          <Hub />
        </ImageContainer>
        <LinkText $isActive={isOpen || pathname.toLowerCase().includes('hub')}>{i18n.t('My hub')}</LinkText>
      </DropdownButton>
      <SitebarDropdown dataTestId="sitebar-hub-menu" isOpen={isOpen}>
        <Divider>{i18n.t('Communities')}</Divider>
        <ul data-testid="hub-menu-community-list">
          {filteredCommunities.map(({ slug, logoImageHash, name }) => (
            <li key={slug}>
              <Link
                href={`/${slug}`}
                onClick={() => setOpenHubMenu(false)}
              >
                <Avatar alt={`${name} image`} url={getCommunityLogoUrl(logoImageHash, true)} />
                {name}
              </Link>
            </li>
          ))}
          {((!showAllCommunities && followedCommunities.length <= 3) || showAllCommunities) && (
            <li>
              <Link
                legacyBehavior
                passHref
                as="/search/communities"
                href="/search?searchType=community"
              >
                <FindCommunityLink onClick={() => {
                  trackFootfallEvent(Event.Clicked, { clickType: 'hub-menu', clickMetadata: { section: Section.CommunitySearch } });
                }}
                >
                  <Avatar alt="Find a community" url={`${config.cdn.bg}BrowseCommunities.svg`} />
                  {i18n.t('Find a community')}
                </FindCommunityLink>
              </Link>
            </li>
          )}
        </ul>
        {followedCommunities.length > 3 && (
          <SitebarDropdownLink onClick={() => setShowAllCommunities(!showAllCommunities)}>
            {showAllCommunities ? `- ${i18n.t('view less')}` : `+ ${i18n.t('view all your communities')}`}
          </SitebarDropdownLink>
        )}
        <Divider>
          {i18n.t('Programs')}{' '}
          <Badge isNew={true}>{i18n.t('NEW')}</Badge>
        </Divider>
        {programs.length || loading
          ? (
            <ul>
              {filteredPrograms.map(({ code, name }) => (
                <li key={code}>
                  <Link
                    as={`/programs/${code}`}
                    href={`/programs?slug=${code}`}
                    onClick={() => trackFootfallEvent(Event.Clicked, { clickMetadata: { section: Section.Program, element: name }, clickType: 'hub-menu' })}
                  >
                    <Avatar alt={`${name} image`} url={`${PROGRAM_URL}${code}.png`} />
                    {name}
                  </Link>
                </li>
              ))}
              {programs.length > 3 && (
                <li>
                  <SitebarDropdownLink onClick={() => setShowAllPrograms(!showAllPrograms)}>
                    {showAllPrograms ? `- ${i18n.t('view less')}` : `+ ${i18n.t('view all your programs')}`}
                  </SitebarDropdownLink>
                </li>
              )}
            </ul>
          ) : <ProgramsEmptyState />
        }
      </SitebarDropdown>
    </div>
  );
}

type Program = {
  name: string;
  id: string;
  params: string;
  code: string;
}
type SuggestedProgramLinkProps = {
  program: Program;
}
const SuggestedProgramLink = ({ program }: SuggestedProgramLinkProps) => {
  const { name, id, params } = program;
  const href = `/${id}${params ? params : ''}`;
  const code = id.replace('programs/', '');
  return (
    <li>
      <SuggestedProgramLinkItem
        href={href}
        onClick={() => {
          trackFootfallEvent(Event.Clicked, { clickMetadata: { section: Section.Program, element: name }, clickType: 'hub-menu' });
        }}
      >
        <Avatar alt={`${name} image`} url={`${PROGRAM_URL}${code}.png`} />
        { name }
      </SuggestedProgramLinkItem>
    </li>
  );
};

const ProgramsEmptyState = () => {
  const programs = discoverLinks.find(({ id }) => id === 'programs').links;
  return (
    <div>
      <ProgramEmptyStateSubtitle>{i18n.t("Looks like you're not enrolled in any programs yet!")}</ProgramEmptyStateSubtitle>
      <InformationCard>
        <ProgramEmptyStateText>{i18n.t('Browse programs')}</ProgramEmptyStateText>
        <SuggestedProgramList>
          { programs.map(program => <SuggestedProgramLink key={program.id} program={program} />) }
        </SuggestedProgramList>
      </InformationCard>
    </div>
  );
};

function useFollowedCommunities(isOpen: boolean, showAllCommunities: boolean) {
  const followedCommunities = useSelector(({ context }) => context.currentUser?.followedCommunities);
  const [filteredCommunities, setFilteredCommunities] = useState([]);

  useEffect(() => {
    if (isOpen && followedCommunities.length > 0) {
      const sortedCommunities = followedCommunities.sort((a, b) => a.name.localeCompare(b.name));
      if (showAllCommunities) {
        setFilteredCommunities(sortedCommunities);
      } else {
        setFilteredCommunities(sortedCommunities.slice(0, COMMUNITIES_TO_SHOW));
      }
    }
  }, [followedCommunities, isOpen, showAllCommunities]);

  return filteredCommunities;
}

function usePrograms(isOpen: boolean) {
  const userId = useSelector(({ context }) => context.currentUser?.userId);
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPrograms = useCallback(async () => {
    try {
      const { data } = await get(`private/programs/subscribed?user-id=${userId}`);
      setLoading(false);
      setPrograms(data);
    } catch (ex) {
      captureException(ex);
    }
  }, [userId]);

  useEffect(() => {
    if (isOpen) fetchPrograms();
  }, [isOpen, fetchPrograms]);

  return { programs, loading };
}

const FindCommunityLink = styled.a`
  && { // precedence boost needed
    color: ${({ theme }) => theme.colorBlue};
  }
`;
const SuggestedProgramLinkItem = styled.a`
  padding: 7.5px 0 !important;
  &:hover, &:focus, &:active {
    background-color: ${({ theme }) => theme.colorWhite} !important;
  }
`;
const ProgramEmptyStateSubtitle = styled.div`
  ${text};
  padding: 15px;
`;
const ProgramEmptyStateText = styled.div`
  ${textLight};
  padding-bottom: 10px;
`;
const SuggestedProgramList = styled.ul`
  margin: 0;
`;
