import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { textWideSpacing } from 'styles-js/mixins/typography';

type Props = {
  children: ReactNode;
  className?: string;
  withColumns?: boolean;
}
export default function Section({ children, className, withColumns }: Props) {
  return (
    <Wrapper>
      <Content $withColumns={withColumns} className={className}>
        {children}
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  ${textWideSpacing}

  &:nth-of-type(even) {
    background-color: ${({ theme }) => theme.colorGreenLighter};
  }

  p, form {
    margin-bottom: 15px;
  }
`;

const Content = styled.div<{ $withColumns?: boolean }>`
  padding: 70px 15px;
  max-width: 1200px;
  margin: 0 auto;

  @media (${({ theme }) => theme.overScreenXlarge}) {
    padding: 90px 15px;
  }

  @media (${({ theme }) => theme.underScreenMedium}) {
    padding: 55px 15px;
  }

  @media (${({ theme }) => theme.underScreenSmall}) {
    padding: 40px 15px;
    line-height: 24px;
  }

  ${({ $withColumns }) => $withColumns && css`
    display: flex;

    @media (${({ theme }) => theme.underScreenMedium}) {
      flex-direction: column;
    }
  `}
`;
