// @ts-strict-ignore
import styled from 'styled-components';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import Button from 'src/components/buttons/button';
import { helpScoutArticle } from 'src/utils/helpScoutBeacon';
import Separator from 'src/components/separator';
import { textLight, titleLarge } from 'styles-js/mixins/typography';

export default function Details() {
  const { currentCommunity, currentUser } = useSelector(({ context }) => context);

  return (
    <Wrapper>
      <h2>{currentCommunity.name}</h2>
      {(currentCommunity?.isArchived) ? (
        <Subtitle>
          <span>{currentCommunity?.postCount?.toLocaleString()} {i18n.t('posts')}</span>
          <Separator />
          <ArchivedButton
            icon="information"
            iconLeftAligned={false}
            type="link"
            onClick={() => helpScoutArticle('172-what-are-archived-communities')}
          >
            {i18n.t('Archived')}
          </ArchivedButton>
        </Subtitle>
      ) : (currentUser || currentCommunity?.followerCount >= 300) && (
        <Subtitle>
          {currentCommunity?.followerCount?.toLocaleString()} {i18n.t('members')}
          <Separator />
          {currentCommunity?.postCount?.toLocaleString()} {i18n.t('posts')}
        </Subtitle>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-left: 160px;
  @media (${({ theme }) => theme.underScreenSmall}) {
    margin-left: 0;
    text-align: center;
  }
      
  h2 {
    ${titleLarge}
    margin: 0;
    @media (${({ theme }) => theme.underScreenSmall}) {
      margin: 5px 0;
    }
  }
`;

const Subtitle = styled.div`
  ${textLight}
  display: flex;
  flex-wrap: wrap;
  gap: 2px 8px;
  @media (${({ theme }) => theme.underScreenSmall}) {
    margin: 5px 0;
    justify-content: center;
  }
`;

const ArchivedButton = styled(Button)`
  margin: 0;
  color: ${props => props.theme.colorTextLight};
  .icon {
    color: ${props => props.theme.colorButtonPrimary};
    &:before {
      -webkit-font-smoothing: auto;
      -moz-osx-font-smoothing: auto;
    }
  }
`;
