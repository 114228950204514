import { useState } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import config from 'config/config';
import i18n from 'src/utils/translate';
import LanguageChange from 'src/components/languageChange';
import Social from 'src/components/footer/social';
import Sections from 'src/components/footer/sections';
import { ToggleButton } from 'src/components/footer/toggleButton';
import { textLinkWhite, textSmall, titleMedium } from 'styles-js/mixins/typography';
import { useSelector } from 'src/store/store';

const year = new Date().getFullYear();

export default function SiteFooter() {
  const isLoggedIn = useSelector(({ context }) => !!context.currentUser?.userId);
  const [isFooterReduced, setIsFooterReduced] = useState(true);

  return (
    <>
      {isLoggedIn && isFooterReduced && (
        <ReducedFooter>
          <p>&copy; {year} HealthUnlocked</p>
          <ToggleButton isReduced={isFooterReduced} setIsReduced={setIsFooterReduced} />
        </ReducedFooter>
      )}
      <Footer $isHidden={isLoggedIn && isFooterReduced} $isLoggedIn={isLoggedIn}>
        <FooterWrapper data-testid="top-footer">
          <LinkSections>
            <div data-testid="footer-section-1">
              <Image
                alt="HealthUnlocked"
                data-testid="hu-logo"
                height={18}
                src={`${config.cdn.static}img/healthunlocked-logo-white.png`}
                width={172}
              />
              <Copy data-testid="copyright-statement">&copy; {year} {i18n.t('All rights reserved.')}</Copy>
              <Social />
            </div>
            <Sections />
            {isLoggedIn && <ToggleButton isReduced={isFooterReduced} isWhite={true} setIsReduced={setIsFooterReduced} />}
          </LinkSections>
        </FooterWrapper>
        <BottomWrapper data-testid="bottom-footer">
          <BottomSection>
            <LanguageChange />
          </BottomSection>
        </BottomWrapper>
      </Footer>
    </>
  );
}

const Footer = styled.footer<{ $isHidden: boolean, $isLoggedIn: boolean }>`
  @media (${({ theme }) => theme.underScreenSmall}) {
    display: ${({ $isHidden }) => $isHidden ? 'none' : 'block'};
    margin-bottom: ${({ $isLoggedIn, theme }) => $isLoggedIn ? theme.navHeightBottom : 0};
  }
  @media print { // for safari
    display: none;
  }
`;

const ReducedFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.navHeightBottom};
  @media (${({ theme }) => theme.overScreenSmall}) {
    display: none;
  }
  @media print {
    display: none;
  }
`;

const FooterWrapper = styled.div`
  background-color: ${({ theme }) => theme.colorBlack};
`;

const LinkSections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  padding: 60px 15px;
  cursor: pointer;

  @media (${({ theme }) => theme.overScreenSmall}) {
    flex-direction: row;
    cursor: default;
  }

  h2 {
    ${titleMedium};
    color: ${({ theme }) => theme.colorTextWhite};
    margin: 0;
  }
  a, button {
    ${textLinkWhite};
    padding: 0;
    &:hover, &:focus, &:active {
      ${textLinkWhite}
    }
  }
`;

const Copy = styled.div`
  ${textSmall}
  color: ${({ theme }) => theme.colorWhite};
  margin-top: 15px;
  margin-bottom: 35px;
`;

const BottomWrapper = styled.div`
  background-color: ${({ theme }) => theme.colorBlackDark};
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: row-reverse;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
  background-color: ${({ theme }) => theme.colorBlackDark};
  @media (${({ theme }) => theme.underScreenSmall}) {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }
`;
