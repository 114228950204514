// @ts-strict-ignore
import styled from 'styled-components';
import { useRouter } from 'next/router';
import FollowButton from 'src/components/buttons/followButton/followButton';
import WriteButton from 'src/modules/community/shared/components/writeButton';
import { Element, Section } from 'src/constants/footfall';
import { hideWriteCTA } from 'src/constants/restrictedCommunities';
import AdminActions from 'src/modules/community/shared/components/header/adminActions';
import { Role } from 'src/constants/types';
import { useIsCommunityMember, userHasRole } from 'src/utils/context';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import { useBranch } from 'src/utils/cookie';
import SearchBar from 'src/modules/search/shared/components/searchBar';
import { useMediaQuery } from 'src/utils/mediaQuery';

export default function CommunityActions({ currentPage }: { currentPage: string }) {
  const currentCommunity = useSelector(({ context }) => context.currentCommunity);
  const currentUser = useSelector(({ context }) => context.currentUser);
  const isCommunityMember = useIsCommunityMember();
  const isHUStaff = userHasRole(currentUser, [Role.HealthUnlocked]);
  const isArchivedCommunityAndHUStaff = currentCommunity?.isArchived && isHUStaff;

  const isB = useBranch() === 'B';
  const isMobile = useMediaQuery('underScreenSmall');
  const router = useRouter();
  const isMemberSearch = router.query.searchType === 'members' || router.pathname === '/[communitySlug]/members';
  const searchType = isMemberSearch ? 'members' : 'posts';

  if (!isArchivedCommunityAndHUStaff && currentCommunity?.isArchived) return null;

  return (
    <Wrapper>
      {isB && isMobile && (
        <StyledSearchBar
          disableAutosuggest={isMemberSearch}
          isCommunitySearch={true}
          placeholder={i18n.t('Search community')}
          searchType={searchType}
        />
      )}
      {isHUStaff || (isB && isMobile && isCommunityMember)
        ? <AdminActions />
        : (
          <FollowButton
            buttonType={isB && isMobile ? 'primary' : 'secondary'}
            clickMetadata={{ element: Element.Follow, section: ('community-' + currentPage) as Section }}
            community={currentCommunity}
            followType="community"
            isFollowing={isCommunityMember}
          />
        )}
      {isB && isMobile ? null : !hideWriteCTA.includes(currentCommunity?.slug) && (
        <WriteButton trackingSection={'community-' + currentPage as Section}>
          {i18n.t('Write')}
        </WriteButton>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  @media (${({ theme }) => theme.overScreenSmall}) {
    margin-left: auto;
  }
`;

const StyledSearchBar = styled(SearchBar)`
  align-items: center;
  height: 36px;
  margin-bottom: 0;
  .search-bar__container {
    height: 36px;
    margin-bottom: 0;
  }
  .search-bar__input {
    width: 185px;
    @media (${({ theme }) => theme.underScreenXxsmall}) {
      width: 140px;
    }
  }
  .react-autosuggest__suggestions-container {
    margin-top: -5px;
  }
`;
