import { useEffect, useState, useRef, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { throttle } from 'lodash';
import { useDispatch, useSelector } from 'src/store/store';
import { showSignupModal } from 'src/modules/shared/modal/actions';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Element, Section } from 'src/constants/footfall';
import i18n from 'src/utils/translate';
import { getLocalStorageItem, Key, setLocalStorageItem } from 'src/utils/localStorage';
import { useIsLoggedIn } from 'src/utils/hooks/useIsLoggedIn';
import Animate from 'src/components/animate';
import { resetFloatingSignupBox } from 'src/modules/shared/context/actions';
import Button from 'src/components/buttons/button';
import { text, textLight, textSmall, titleMedium, titleSmall } from 'styles-js/mixins/typography';

export default function FloatingBox() {
  const dispatch = useDispatch();
  const nodeRef = useRef(null);
  const isLoggedIn = useIsLoggedIn();
  const isFloatingSignupBoxVisible = useSelector(({ context }) => context.isFloatingSignupBoxVisible);

  const [showFloatingSignupBox, setShowFloatingSignupBox] = useState(false);
  const [email, setEmail] = useState('');

  const onScroll = throttle(() => {
    if (window.scrollY > 0) {
      setShowFloatingSignupBox(true);
    }
  }, 300);

  const hide = () => {
    setShowFloatingSignupBox(false);
    window.removeEventListener('scroll', onScroll);
    setLocalStorageItem(Key.hideFloatingBox, 'true');
  };

  const onSubmitEmail = (e: SyntheticEvent) => {
    e.preventDefault();
    trackFootfallEvent(Event.Clicked, {
      clickMetadata: { section: Section.StickyFooter, element: Element.Join },
      clickType: 'sign-up',
    });
    dispatch(showSignupModal({ defaultEmail: email }));
  };

  useEffect(() => {
    if (getLocalStorageItem(Key.hideFloatingBox)) setShowFloatingSignupBox(false);
    if (!isFloatingSignupBoxVisible) dispatch(resetFloatingSignupBox());

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoggedIn || !isFloatingSignupBoxVisible) return null;

  return (
    <Animate show={showFloatingSignupBox} transitionType="TRANSITION-simpleFade">
      <Container ref={nodeRef}>
        <Inner>
          <CloseButton
            icon="close"
            type="none"
            onClick={hide}
          />
          <Title>
            <span className="hidden-xs">
              {i18n.t('Connect with others who understand.')}
            </span>
            <span className="visible-xs">
              {i18n.t('Join the #1 peer-support network for health. Ask questions. Get answers.')}
            </span>
          </Title>
          <Content>
            <span className="hidden-xs">
              {i18n.t('Join the #1 peer-support network for health. Ask questions. Get answers.')}
            </span>
            <Form onSubmit={e => onSubmitEmail(e)}>
              <EmailInput
                placeholder={i18n.t('email@address.com')}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <JoinButton isSubmit={true} size="lg">
                <span className="hidden-xs">{i18n.t('Join HealthUnlocked')}</span>
                <span className="visible-xs">{i18n.t('Join')}</span>
              </JoinButton>
            </Form>
          </Content>
        </Inner>
      </Container>
    </Animate>
  );
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colorBlueDarker};
  box-shadow: ${({ theme }) => theme.shadowTop};
  z-index: 1049;
  color: ${({ theme }) => theme.colorWhite};
  text-align: center;
  @media (${({ theme }) => theme.underScreenXsmall}) {
    text-align: left;
  }
`;

const Inner = styled.div`
  padding: 20px 20px 26px;
  @media (${({ theme }) => theme.underScreenSmall}) {
    padding: 10px 10px 13px;
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 17px;
  color: ${({ theme }) => theme.colorGreyDark};
  &:hover, &:focus, &:active {
    color: ${({ theme }) => theme.colorGrey};
  }
`;

const Title = styled.div`
  ${titleMedium};
  color: ${({ theme }) => theme.colorWhite};
  @media (${({ theme }) => theme.underScreenSmall}) {
    ${titleSmall};
    color: ${({ theme }) => theme.colorWhite};
  }
`;

const Content = styled.div`
  ${text};
  color: ${({ theme }) => theme.colorWhite};
  @media (${({ theme }) => theme.underScreenMedium}) {
    ${textSmall};
    color: ${({ theme }) => theme.colorWhite};
  }
`;

const Form = styled.form`
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
  @media (${({ theme }) => theme.underScreenMedium}) {
    margin-top: 5px;
  }
  @media (${({ theme }) => theme.underScreenXsmall}) {
    gap: 8px;
  }
`;

const EmailInput = styled.input`
  ${textLight};
  width: 350px;
  padding: 12px 20px;
  border: ${({ theme }) => theme.greyLine};
  border-radius: 0.25rem;
  &:focus {
    color: ${({ theme }) => theme.colorBlack};
  }
  @media (${({ theme }) => theme.underScreenSmall}) {
    padding: 6px 12px;
  }
  @media (${({ theme }) => theme.underScreenXsmall}) {
    width: 70%;
    min-width: 218px;
  }
`;

const JoinButton = styled(Button)`
  margin: 0;
  @media (${({ theme }) => theme.underScreenSmall}) {
    padding: 8px 20px;
  }
`;
