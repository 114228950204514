import { Children, cloneElement, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import i18n from 'src/utils/translate';
import ButtonDropdown from 'src/components/buttons/buttonDropdown';
import { text } from 'styles-js/mixins/typography';
import { useNavBarOverflow } from 'src/utils/hooks/useNavBarOverflow';

type Props = {
  isCompact?: boolean;
  children: (ReactElement | false)[]; // false for optional children eg. {isMobile && <Child />}
  className?: string;
}
export default function NavBar({ isCompact, children, className }: Props) {
  const { visibleItemsNumber, navRef } = useNavBarOverflow(children.length);

  const navItems = children.filter(Boolean) as ReactElement[];
  const visibleItems = navItems.slice(0, visibleItemsNumber);
  const dropdownItems = navItems.slice(visibleItemsNumber);

  return (
    <Nav
      $isCompact={isCompact}
      className={className}
      data-testid="nav-bar"
      ref={navRef}
    >

      {visibleItems}

      {dropdownItems.length > 0 && (
        <StyledButtonDropdown
          $isCompact={isCompact}
          color="none"
          dataTestId="navbar-more"
          isRightAligned={true}
          text={i18n.t('More')}
        >
          {Children.map(dropdownItems, (item) => <DropdownItem item={item} />)}
        </StyledButtonDropdown>
      )}
    </Nav>
  );
}

function DropdownItem({ item }: { item: ReactElement }) {
  return cloneElement(item, { className: 'dropdown-item', role: 'menuitem', tabIndex: 0 });
}

const Nav = styled.nav<{ $isCompact?: boolean }>`
  display: flex;
  border-bottom: ${({ theme }) => theme.greyLine};
  height: 55px;
  @media (${({ theme }) => theme.underScreenSmall}) {
    height: 45px;
  }
  a {
    ${text};
    display: flex;
    gap: 5px;
    align-items: center;
  }
  > a {
    padding: 0 20px;
    border-bottom: 5px solid transparent;
    &.active {
      border-bottom-color:  ${props => props.theme.colorGreen};
    }
    &:hover, &:focus, &:active {
      color: ${props => props.theme.colorTextBlack};
      border-bottom-color:  ${props => props.theme.colorGreen};
    }
    
    @media (${({ theme }) => theme.underScreenSmall}) {
      padding: 0 8px;
    }

    ${({ $isCompact }) => $isCompact && css`
      @media (${({ theme }) => theme.underScreenSmall}) {
        font-size: ${props => props.theme.fontSizeXs};
      }
      @media (${({ theme }) => theme.underScreenXsmall}) {
        padding: 0 5px; // for community header
      }
    `}
  }
`;

const StyledButtonDropdown = styled(ButtonDropdown)<{ $isCompact?: boolean }>`
  .dropdown-toggle {
    height: 100%;
    margin: 0;
    padding: 0 20px;
    border: 0;
    border-bottom: 5px solid transparent;
    @media (${({ theme }) => theme.underScreenSmall}) {
      padding: 0 8px;
    }
  }

  ${({ $isCompact }) => $isCompact && css`
    .dropdown-toggle {
      @media (${({ theme }) => theme.underScreenSmall}) {
        font-size: ${props => props.theme.fontSizeXs};
      }
      @media (${({ theme }) => theme.underScreenXsmall}) {
        padding: 0 5px; // for community header
      }
    }
  `}
`;
