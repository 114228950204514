// @ts-strict-ignore
import { ReactNode, useState } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import styled, { css } from 'styled-components';

type Props = {
  children: ReactNode;
  ariaLabel?: string;
  caret?: boolean;
  className?: string;
  color?: 'primary' | 'secondary' | 'actions' | 'grey' | 'languages'  | 'none';
  dataTestId?: string;
  isDisabled?: boolean;
  isRightAligned?: boolean;
  text?: string;
}
export default function CustomButtonDropdown({
  ariaLabel = '',
  caret = true,
  children,
  className = '',
  color = 'secondary',
  dataTestId = null,
  isDisabled = false,
  isRightAligned = false,
  text = '',
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = (event) => {
    if (isDisabled) {
      event.preventDefault();
    } else {
      event.stopPropagation();
      event.preventDefault();
      // Toggle open state
      setIsOpen(!isOpen);
      event.stopPropagation();
    }
  };

  return (
    <ButtonDropdown
      className={className}
      disabled={isDisabled}
      isOpen={isOpen}
      toggle={onClick}
    >
      <StyledDropdownToggle
        aria-label={ariaLabel}
        caret={caret}
        color={color}
        data-testid={dataTestId}
        disabled={isDisabled}
      >
        {text}
      </StyledDropdownToggle>
      <DropdownMenu right={isRightAligned}>
        {children}
      </DropdownMenu>
    </ButtonDropdown>
  );
}

const StyledDropdownToggle = styled(DropdownToggle)<{ caret?:boolean; isDisabled?: boolean }>`
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  ${props => props.caret && css`
    &::after { // caret
      content: '\f004'; // icon-open-down
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      font-size: 1em;
      display: inline-block;
      text-decoration: inherit;
      width: 1em;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      border: 0;
      margin: 0 0 0 5px;
      vertical-align: 0;
    }
  `};

  &.btn:not(.dropdown-toggle) { // overwrite reactstrap default styles when caret=false
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  &.disabled {
    color: ${({ theme }) => theme.colorBlack} !important;
    background-color: ${({ theme }) => theme.colorBlueBackground} !important;
    border: none;
    opacity: 1;
  }

  // The following classes are set with the 'color' prop
  &.btn-primary {
    margin: 5px;
    padding: 6px 12px;
    color: ${({ theme }) => theme.colorButtonPrimaryText};
    background-color: ${({ theme }) => theme.colorButtonPrimary};
    border-color: ${({ theme }) => theme.colorButtonPrimary};
  }

  &.btn-secondary {
    margin: 5px;
    padding: 6px 12px;
    color: ${({ theme }) => theme.colorButtonSecondaryHover};
    border-color: ${({ theme }) => theme.colorButtonSecondaryHover};
    background-color: ${({ theme }) => theme.colorWhite};
  }

  &.btn-actions {
    color: ${({ theme }) => theme.colorTextLight};
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
    &:hover, &:focus, &:active {
      color: ${({ theme }) => theme.colorTextBlack};
    }
  }

  &.btn-grey {
    border-color: ${({ theme }) => theme.colorGreyLight};
  }

  &.btn-languages {
    color: ${({ theme }) => theme.colorWhite};
    background-color: ${({ theme }) => theme.colorBlackDark};
  }
`;
