// @ts-strict-ignore
import Link from 'next/link';
import { useState } from 'react';
import styled from 'styled-components';
import { helpScoutArticle } from 'src/utils/helpScoutBeacon';
import i18n from 'src/utils/translate';
import Button from 'src/components/buttons/button';
import Icon from 'src/components/icon';

const onCookiesSettingsClick = () => {
  if (window.CookieControl) {
    window.CookieControl.open();
  }
};

export default function Sections() {
  const [isCompanyHidden, setIsCompanyHidden] = useState(true);
  const [isPlatformHidden, setIsPlatformHidden] = useState(true);
  const [isTermsHidden, setIsTermsHidden] = useState(true);

  return (
    <>
      <div data-testid="footer-section-2">
        <SectionHeader onClick={() => setIsCompanyHidden(!isCompanyHidden)}>
          <h2 data-testid="section-header-2">{i18n.t('Company')}</h2>
          <OpenIcon isOpen={isCompanyHidden} />
        </SectionHeader>
        <LinkList $isHidden={isCompanyHidden}>
          <li>
            <a
              data-testid="link-1"
              href="https://about.healthunlocked.com"
              rel="noreferrer"
              target="_blank"
            >
              {i18n.t('About Us')}
            </a>
          </li>
          <li>
            <a
              data-testid="link-2"
              href="https://about.healthunlocked.com/research-solutions"
              rel="noreferrer"
              target="_blank"
            >
              {i18n.t('Research Solutions')}
            </a>
          </li>
          <li>
            <a
              data-testid="link-3"
              href="https://about.healthunlocked.com/careers"
              rel="noreferrer"
              target="_blank"
            >
              {i18n.t('Careers')}
            </a>
          </li>
          <li>
            <Link data-testid="link-4" href="/blog">
              {i18n.t('Blog')}
            </Link>
          </li>
        </LinkList>
      </div>
      <div data-testid="footer-section-3">
        <SectionHeader onClick={() => setIsPlatformHidden(!isPlatformHidden)}>
          <h2 data-testid="section-header-3">{i18n.t('Platform')}</h2>
          <OpenIcon isOpen={isPlatformHidden} />
        </SectionHeader>
        <LinkList $isHidden={isPlatformHidden}>
          <li data-testid="link-5">
            <Button type="none" onClick={() => window.HelpScoutBeacon.triggerHelpScoutBeacon('USER_SUPPORT')}>
              {i18n.t('Help Centre')}
            </Button>
          </li>
          <li>
            <Link as="/tags" data-testid="link-6" href="/tagsList">
              {i18n.t('Health terms A to Z')}
            </Link>
          </li>
          <li>
            <Link data-testid="link-7" href="/communities">
              {i18n.t('Communities A to Z')}
            </Link>
          </li>
          <li>
            <a
              data-testid="link-8"
              href="https://about.healthunlocked.com/start-a-community"
              rel="noreferrer"
              target="_blank"
            >
              {i18n.t('Start a Community')}
            </a>
          </li>
          <li data-testid="link-9">
            <Button type="none" onClick={onCookiesSettingsClick}>
              {i18n.t('Cookies settings')}
            </Button>
          </li>
        </LinkList>
      </div>
      <div data-testid="footer-section-4">
        <SectionHeader onClick={() => setIsTermsHidden(!isTermsHidden)}>
          <h2 data-testid="section-header-4">{i18n.t('Terms & Policies')}</h2>
          <OpenIcon isOpen={isTermsHidden} />
        </SectionHeader>
        <LinkList $isHidden={isTermsHidden}>
          <li>
            <Button dataTestId="link-10" type="none" onClick={() => helpScoutArticle('147-terms', { type: 'modal' })}>
              {i18n.t('Terms of Use')}
            </Button>
          </li>
          <li>
            <Button dataTestId="link-11" type="none" onClick={() => helpScoutArticle('148-privacy', { type: 'modal' })}>
              {i18n.t('Privacy Policy')}
            </Button>
          </li>
          <li>
            <Button dataTestId="link-12" type="none" onClick={() => helpScoutArticle('150-cookies', { type: 'modal' })}>
              {i18n.t('Cookie Policy')}
            </Button>
          </li>
          <li>
            <Button dataTestId="link-13" type="none" onClick={() => helpScoutArticle('151-security', { type: 'modal' })}>
              {i18n.t('Security Policy')}
            </Button>
          </li>
          <li>
            <Button dataTestId="link-14" type="none" onClick={() => helpScoutArticle('205-code-of-conduct-for-moderation-team-members', { type: 'modal' })}>
              {i18n.t('Code of Conduct for Moderation')}
            </Button>
          </li>
          <li>
            <Button dataTestId="link-15" type="none" onClick={() => helpScoutArticle('149-terms-for-community-partners', { type: 'modal' })}>
              {i18n.t('Terms for Community Partners')}
            </Button>
          </li>
        </LinkList>
      </div>
    </>
  );
}

const OpenIcon = ({ isOpen }) => <Icon icon={isOpen ? 'open-down' : 'open-up'} />;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon {
    cursor: pointer;
    color: ${({ theme }) => theme.colorWhite};
    @media (${({ theme }) => theme.overScreenSmall}) {
      display: none;
    }
  }
`;

const LinkList = styled.ul<{ $isHidden: boolean }>`
  display: ${({ $isHidden }) => $isHidden ? 'none' : 'flex'};
  flex-direction: column;
  gap: 8px;
  margin: 15px 0 0 0;
  padding: 0;
  list-style: none;
  
  @media (${({ theme }) => theme.overScreenSmall}) {
    display: flex;
  }
`;
