import Link from 'next/link';
import { ReactNode, SyntheticEvent } from 'react';
import { PostType } from 'src/constants/types';

type Props = {
  anchorToResponses?: boolean;
  children: ReactNode;
  className?: string;
  communitySlug: string;
  disabled?: boolean;
  isPrivate: boolean;
  onClick?: (e: SyntheticEvent) => void;
  postId: number;
  postTypeId: PostType;
  responseId?: number;
  urlEncodedTitle: string;
}
export default function PostLink({
  anchorToResponses,
  children,
  className,
  communitySlug,
  disabled = false,
  isPrivate,
  onClick = () => {},
  postId,
  postTypeId,
  responseId,
  urlEncodedTitle,
}: Props) {

  if (disabled) {
    return (
      <a href="#" onClick={onClick}>{children}</a>
    );
  }

  const isScrollToResponses = anchorToResponses || responseId;

  return (
    <Link
      className={className}
      href={getPostUrl({ anchorToResponses, communitySlug, isPrivate, postId, postTypeId, urlEncodedTitle, responseId })}
      scroll={!isScrollToResponses}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}

type Args = {
  anchorToResponses?: boolean;
  communitySlug: string;
  isPrivate: boolean;
  postTypeId: PostType;
  postId: number;
  responseId?: number;
  urlEncodedTitle: string;
}
function getPostUrl({ anchorToResponses, communitySlug, isPrivate, postTypeId, postId, responseId, urlEncodedTitle }: Args): string {
  const postType = postTypeId === PostType.Poll ? 'polls' : 'posts';
  const privatePath = isPrivate ? '/private' : '';
  const params = responseId ? `?responses=${responseId}` : anchorToResponses ? `?responses` : '';
  return `/${communitySlug}/${postType}${privatePath}/${postId}/${urlEncodedTitle}${params}`;
}
